import {useState} from "react";
import styled from "@emotion/styled";
import Tooltip, { HandlePosition } from "./Tooltip";
import { StaticImageData } from "next/image";

const TooltipWrapper = styled.div([
    {
        position: "absolute",
        transition: "opacity 0.2s ease-out",
    }
]);

const InfoImageDesktop = styled.img(({ theme }) => ([
    {
        /* for mobile */
        [theme.splus3.breakpoints.mobile]: [
            {
                display: "none",
            }
        ],
    }
]));

const InfoImageMobile = styled.img(({ theme }) => ([
    {
        display: "none",

        /* for mobile */
        [theme.splus3.breakpoints.mobile]: [
            {
                display: "initial"
            }
        ],
    }
]));

interface HeaderWithTooltipProps {
    header: string;
    icon: StaticImageData;
    size: number;
    handlePosition: HandlePosition;
    left: number;
    bottom: number;
}

const HeaderWithTooltip: React.FC<HeaderWithTooltipProps> = ({ header, icon, size, handlePosition, left, bottom, children }) => {
    const [tooltipVisible, setTooltipVisible] = useState(false);
    const [tooltipBlocked, setTooltipBlocked] = useState(false);
    const [timeoutRef , setTimeoutRef] = useState<NodeJS.Timeout | null>(null);

    const handleMouseLeave = () => {
        setTimeoutRef(setTimeout(() => {
            if (!tooltipBlocked) {
                setTooltipVisible(false);
            }
        }, 500))
    };

    const handleMouseEnter = () => {
        if (timeoutRef) {
            clearTimeout(timeoutRef);
        }
        setTooltipVisible(true);
    };

    return (
        <div style={{ width: size, height: size, display: "inline" }}>
            <InfoImageDesktop height={size}
                              src={icon.src}
                              alt="info circle"
                              onMouseEnter={handleMouseEnter}
                              onMouseLeave={handleMouseLeave}
                              onFocus={() => setTooltipVisible(true)}
                              onBlur={() => setTooltipVisible(false)}
                              onKeyDown={(e) => {
                                  if (e.key === 'Escape' || e.code === 'Escape') {
                                  setTooltipVisible(false);
                                }
                              }}
                              tabIndex={0}/>
            <InfoImageMobile height={size} src={icon.src} alt="info circle" onClick={() => setTooltipVisible(true)} />
            <span style={{ width: 0, height: 0, position: "relative", bottom: 0, left: 0, zIndex: 10, }}>
                <TooltipWrapper style={{ opacity: tooltipVisible ? 1 : 0, left, bottom }}
                                onMouseEnter={() => {
                                    if (timeoutRef) {
                                        clearTimeout(timeoutRef)
                                    }
                                    setTooltipBlocked(true)}
                                }
                                onMouseLeave={() => {
                                    setTooltipBlocked(false)
                                    setTooltipVisible(false)}
                                }>
                    {tooltipVisible && (
                        <Tooltip handlePosition={handlePosition}
                                 header={header}
                                 onClose={() => setTooltipVisible(false)}>
                                 {children}
                        </Tooltip>
                    )}
                </TooltipWrapper>
            </span>
        </div>
    );
}

export default HeaderWithTooltip;