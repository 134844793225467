import styled from "@emotion/styled";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { isDeviceActive, isFinished, isRouterActive, isServerActive, isSpeedtestRunning, selectPrepareResult } from "../../../../features/measurement/utils";
import { theme } from "../../../../theme/theme";
import ProgressAnimation from "../common/ProgressAnimation";
import NavigationButton from "../../elements/NavigationButton";
import { RedButtonDiv } from "../../elements/Buttons";
import MeasurementItem from "../common/MeasurementItem";
import { getDeviceName } from "../../../../helper/getDeviceText";
import t from "../../../../helper/t";
import { isMobileDevice } from "../common/utils";
import { BottomInfoContainer } from "../mobile/MeasurementMobile";
import { BottomInfoDiv, InfoContainer, InfoKeySpan, InfoSeparatorSpan } from "../../result/currentMeasurement/resultElements";
import getSpeedtestId from "../../../../helper/utils/getSpeedtestId";
import CopyableData from "../../elements/CopyableData";
import * as R from "ramda";
import MeasurementText from "../../../../features/measurement/MeasurementText";
import { AnimatedRectangleHeight, DashLength, DashWidth, MeasurementItemGap, MeasurementItemHeight, MeasurementItemWidth } from "./consts";
import { animated } from "./AnimationElements";

import internationalSvg from "../../../../../assets/images/splus3/international.svg";
import routerSvg from "../../../../../assets/images/splus3/router.svg";
import mobileSvg from "../../../../../assets/images/splus3/mobile.svg";
import laptopSvg from "../../../../../assets/images/splus3/laptop.svg";

const RootDiv = styled.div(({ theme }) => ([
  {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    flexGrow: 10,

    /* for mobile */
    [theme.splus3.breakpoints.mobile]: {
      display: "none"
    },
  }
]));

const MeasurementContainer = styled.div([
  {
    marginTop: 75 - AnimatedRectangleHeight,
    display: "grid",
    gridTemplateRows: `${AnimatedRectangleHeight}px ${AnimatedRectangleHeight}px auto`,
    gridTemplateColumns: `${MeasurementItemWidth}px ${MeasurementItemGap}px ${MeasurementItemWidth}px ${MeasurementItemGap}px ${MeasurementItemWidth}px`,
  }
]);

const StrokesContainer = styled.div([
  {
    display: "flex",
    flexDirection: "column",

    gridColumnStart: 1,
    gridColumnEnd: 6,
    gridRow: 1,

    marginLeft: MeasurementItemWidth / 2 - DashWidth / 2,
    marginRight: MeasurementItemWidth / 2 - DashWidth / 2,
  }
]);

const StrokesTopContainer = styled.div([
  {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    width: "100%",
  }
]);

const StrokesBottomContainer = styled.div([
  {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "stretch",
    flexGrow: 100,
    width: "100%",
  }
]);

const StrokeTopDiv = styled.div([
  {
    display: "flex",
  }
]);

const StrokeBottomDiv = styled.div([
  {
    paddingTop: 8,
    display: "flex",
  }
]);

const InfoTextDiv = styled.div([
  props => props.theme.splus3.foreground.primary,
  props => props.theme.splus3.typography.large,
]);

interface ProgressContainerProps {
  isFinished: boolean;
}

const ProgressContainer = styled.div<ProgressContainerProps>(({ isFinished }) => ([
  {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    flexGrow: 10,
    maxHeight: 290,

    ["@media (max-height: 720px)"]: {
      paddingTop: isFinished ? 16 : 0,
    }
  }
]));

const ProgressAnimationContainer = styled.div<ProgressContainerProps>(({ isFinished }) => ([
  {
    display: "flex",

    ["@media (max-height: 720px)"]: {
      display: isFinished ? "none" : "flex",
    }
  }
]));

const ButtonContainer = styled.div([
  {
    marginTop: 16,
    marginBottom: 24,
  }
]);

interface MeasurementDesktopProps {
}

const MeasurementDesktop: React.FC<MeasurementDesktopProps> = () => {
  const dispatch = useAppDispatch();
  const state = useAppSelector(state => state.measurement);
  const prepareResult = selectPrepareResult(state);
  const speedtestId = prepareResult === null ? null : getSpeedtestId(prepareResult);

  const getPathOpacity = (position: "left" | "right" | "center"): number => {
    const activeOpacity = 1;
    const inactiveOpacity = 0.3;

    switch (position) {
      case "left": return isServerActive(state) ? activeOpacity : inactiveOpacity;
      case "right": return isDeviceActive(state) ? activeOpacity : inactiveOpacity;
      case "center": return (isServerActive(state) || isDeviceActive(state)) ? activeOpacity : inactiveOpacity;
    }
  }

  const horizontalLine = (): JSX.Element => (
    <svg xmlns="http://www.w3.org/2000/svg" height={DashWidth * 2} width="100%">
      <line x1="0" y1="0" x2="100%" y2="0" strokeWidth={DashWidth * 2} stroke={theme.splus3.colors.defaultForeground} stroke-dasharray={`${DashLength / 2} ${DashLength / 2}`} />
    </svg>
  );

  const verticalLine = (): JSX.Element => (
    <svg xmlns="http://www.w3.org/2000/svg" height={AnimatedRectangleHeight} width={DashWidth * 2}>
      <line x1="0" y1="0" x2="0" y2="100%" strokeWidth={DashWidth * 2} stroke={theme.splus3.colors.defaultForeground} stroke-dasharray={`${DashLength / 2} ${DashLength / 2}`} />
    </svg>
  );

  const finished = isFinished(state);

  return (
    <RootDiv data-cy="measurementElementDesktop">
      <MeasurementContainer>
        <StrokesContainer>
          <StrokesTopContainer>
            <StrokeTopDiv style={{ opacity: getPathOpacity("left") }}>{horizontalLine()}</StrokeTopDiv>
            <StrokeTopDiv style={{ opacity: getPathOpacity("right"), transform: "scale(-1, 1)" }}>{horizontalLine()}</StrokeTopDiv>
          </StrokesTopContainer>

          <StrokesBottomContainer>
            <StrokeBottomDiv style={{ opacity: getPathOpacity("left") }}>{verticalLine()}</StrokeBottomDiv>
            <StrokeBottomDiv style={{ opacity: getPathOpacity("center"), transform: `translate(${DashWidth / 2}px, 0)` }}>{verticalLine()}</StrokeBottomDiv>
            <StrokeBottomDiv style={{ opacity: getPathOpacity("right"), transform: `translate(${DashWidth}px, 0)` }}>{verticalLine()}</StrokeBottomDiv>
          </StrokesBottomContainer>
        </StrokesContainer>

        {animated(state)}

        <div style={{ gridColumn: 1, gridRowStart: 2, gridRowEnd: 4, zIndex: 10 }}>
          <MeasurementItem caption={t("Server")} value="Vodafone" isActive={isServerActive(state)} width={MeasurementItemWidth} height={MeasurementItemHeight}>
            <img style={{ transform: `translate(0px, 100px)` }} src={internationalSvg.src} alt="server" />
          </MeasurementItem>
        </div>
        <div style={{ gridColumn: 3, gridRowStart: 2, gridRowEnd: 4, zIndex: 10 }}>
          <MeasurementItem caption={t("Router")} value={prepareResult?.init.modem?.name} isActive={isRouterActive(state)} width={MeasurementItemWidth} height={MeasurementItemHeight}>
            <img style={{ transform: `translate(0px, 93px)` }} src={routerSvg.src} alt="router" />
          </MeasurementItem>
        </div>
        <div style={{ gridColumn: 5, gridRowStart: 2, gridRowEnd: 4, zIndex: 10 }}>
          <MeasurementItem caption={t("Gerät")} value={getDeviceName(prepareResult) ?? ""} isActive={isDeviceActive(state)} width={MeasurementItemWidth} height={MeasurementItemHeight}>
            {
              isMobileDevice(prepareResult, false) ?
                <img style={{ transform: `translate(0px, 100px)` }} src={mobileSvg.src} alt="mobile" /> :
                <img style={{ transform: `translate(0px, 88px)` }} src={laptopSvg.src} alt="laptop" />
            }
          </MeasurementItem>
        </div>
      </MeasurementContainer>

      <ProgressContainer isFinished={finished}>
        <ProgressAnimationContainer isFinished={finished}>
          <ProgressAnimation />
        </ProgressAnimationContainer>
        {isSpeedtestRunning(state) && (
          <InfoTextDiv>
            <MeasurementText />
          </InfoTextDiv>
        )}
      </ProgressContainer>

      <BottomInfoContainer>
        <BottomInfoDiv>
          {speedtestId !== null && (
            <InfoContainer>
              <InfoKeySpan>{t("Speedtest-ID")}:&nbsp;</InfoKeySpan>
              <CopyableData data={speedtestId}>{speedtestId}</CopyableData>
            </InfoContainer>
          )}
          {!R.isNil(prepareResult?.init.connection.isp) && (
            <>
              <InfoSeparatorSpan>|</InfoSeparatorSpan>
              <InfoContainer>
                <InfoKeySpan>{t("Anbieter")}:&nbsp;</InfoKeySpan>
                <span>{prepareResult?.init.connection.isp}</span>
              </InfoContainer>
            </>
          )}
        </BottomInfoDiv>
      </BottomInfoContainer>
    </RootDiv>
  );
}

export default MeasurementDesktop;
