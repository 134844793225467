import styled from "@emotion/styled";
import { SpeedKind, getSpeedBackground, getSpeedForeground, getSpeedKind } from "../common/resultUtils";
import HeaderWithTooltip from "../../elements/HeaderWithTooltip";
import { currentLangIs } from "src/helper/utils";

import InfoCircleSvg from "@assets/icons/splus3/result-info-circle.svg";
import aboveMaxSpeedDe from "@assets/tooltips/de/above_max_speed.json";
import aboveMaxSpeedEn from "@assets/tooltips/en/above_max_speed.json";

interface RootDivProps {
    speedKind: SpeedKind;
}

const RootDiv = styled.span<RootDivProps>(({ theme, speedKind }) => ([
    getSpeedForeground(theme.splus3, speedKind),
    getSpeedBackground(theme.splus3, speedKind),
    {
        display: "flex",
        gap: 8,
        borderRadius: 128,
        paddingLeft: 12,
        paddingRight: 12,

        alignItems: "center",
    }
]));


const PercentageSpan = styled.span(({ theme }) => ([
    theme.splus3.typography.font16,
    {
        marginTop: 4,
        marginBottom: 4,
        letterSpacing: "0.16px",
        lineHeight: "16px",

        [theme.splus3.breakpoints.mobile]: [
            theme.splus3.typography.font12Bold,
        ],
    }
]));

interface PercentageProps {
    currentSpeed: number;
    bookedSpeedMax: number | null;
    bookedSpeedAvg: number | null;
    bookedSpeedMin: number | null;
    showAdditionalInfo: boolean;
}

const Percentage: React.FC<PercentageProps> = ({ currentSpeed, bookedSpeedMax, bookedSpeedAvg, bookedSpeedMin, showAdditionalInfo }) => {
    if (bookedSpeedMax === null) {
        return null;
    }

    const avg = bookedSpeedAvg === null ? Math.round(bookedSpeedMax * 0.9) : bookedSpeedAvg;
    const min = bookedSpeedMin === null ? Math.round(bookedSpeedMax * 0.6) : bookedSpeedMin;

    const value = Math.round(100.0 * currentSpeed / bookedSpeedMax);
    const kind = getSpeedKind(currentSpeed, avg, min);

    const aboveMaxSpeed = currentLangIs("en") ? aboveMaxSpeedEn : aboveMaxSpeedDe;

    return (
        <RootDiv speedKind={kind}>
            <PercentageSpan>{value}</PercentageSpan>
            <PercentageSpan>%</PercentageSpan>

            {showAdditionalInfo && value > 100 && (
                <HeaderWithTooltip header={aboveMaxSpeed.hintHeader}
                    handlePosition="BottomLeft"
                    icon={InfoCircleSvg}
                    size={19}
                    left={-26}
                    bottom={28}>
                    {aboveMaxSpeed.hintContent}
                </HeaderWithTooltip>
            )}
        </RootDiv>
    );
}

export default Percentage;