import styled from "@emotion/styled";
import { Speed, SpeedContainer, SubtitleContainer, SubtitleSpan } from "./resultElements";
import { getSpeedKind } from "../common/resultUtils";
import ProgressBar from "../common/ProgressBar";
import { NQRecord } from "../../../../features/history/types";
import { nullOrValue } from "../../../../helper/utils";
import t from "../../../../helper/t";
import { connectionToStr } from "../utils";
import Percentage from "./Percentage";

const RootDiv = styled.div(({ theme }) => ([
    {
        display: "flex",
        flexDirection: "column",
        flexGrow: 10,
        padding: 20,

        [theme.splus3.breakpoints.mobile]: {
            padding: 16,
        },
    }
]));

interface UploadCardProps {
    record: NQRecord;
    showAdditionalInfo: boolean;
}

const UploadCard: React.FC<UploadCardProps> = ({ record, showAdditionalInfo }) => {
    const modem = record.prepareResult.init.modem;

    const bookedUploadSpeedMax = nullOrValue(modem.bookedUploadSpeedMax);
    const bookedUploadSpeedAvg = nullOrValue(modem.bookedUploadSpeedAvg);
    const bookedUploadSpeedMin = nullOrValue(modem.bookedUploadSpeedMin);

    const progress = (currentSpeed: number) => {
        if (bookedUploadSpeedMax === null ||
            bookedUploadSpeedAvg === null ||
            bookedUploadSpeedMin === null) {
            return null;
        }

        const speedKind = getSpeedKind(currentSpeed, bookedUploadSpeedAvg, bookedUploadSpeedMin);

        return (
            <ProgressBar
                speedKind={speedKind}
                min={bookedUploadSpeedMin}
                avg={bookedUploadSpeedAvg}
                max={bookedUploadSpeedMax}
                current={currentSpeed}
                showCompareText={showAdditionalInfo} />
        );
    }

    const connectionStr = connectionToStr(record.modemFinal.connectionType);
    const uploadSpeed = record.uploadFinal.speed;
    return (
        <RootDiv data-cy="uploadCard">
            <SpeedContainer>
                <Speed currentSpeed={uploadSpeed} bookedSpeedMax={bookedUploadSpeedMax} />
                <Percentage
                    currentSpeed={uploadSpeed}
                    bookedSpeedMax={bookedUploadSpeedMax}
                    bookedSpeedAvg={bookedUploadSpeedAvg}
                    bookedSpeedMin={bookedUploadSpeedMin}
                    showAdditionalInfo={showAdditionalInfo}
                />
            </SpeedContainer>
            <SubtitleContainer>
                <SubtitleSpan>
                    {t("Messung an Deinem Gerät")} {connectionStr === null ? "" : `(${connectionStr})`}
                </SubtitleSpan>
            </SubtitleContainer>
            {progress(uploadSpeed)}
        </RootDiv>
    );
}

export default UploadCard;