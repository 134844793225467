import styled from "@emotion/styled";
import { Speed, SpeedContainer, SubtitleSpan, SubtitleContainer } from "../resultElements";
import { nullOrValue } from "../../../../../helper/utils";
import { progress, connectionSubtitle } from "./utils";
import { DownloadCardProps } from "./DownloadCardProps";
import Percentage from "../Percentage";

const RootDiv = styled.div(({ theme }) => ([
    {
        display: "flex",
        flexGrow: 10,
        padding: 20,
        columnGap: "3.6vw",

        [theme.splus3.breakpoints.smallDesktop]: {
            columnGap: "5vw",
        },

        [theme.splus3.breakpoints.mobile]: {
            flexDirection: "column",
            rowGap: 25,
            padding: 16,
        },
    }
]));

const LeftPartDiv = styled.div([
    {
        display: "flex",
        flexDirection: "column",
        flexGrow: 10,
    }
]);

const DownloadCard1: React.FC<DownloadCardProps> = ({ record, showAdditionalInfo }) => {
    const downloadSpeed = record.downloadFinal.speed;
    const modem = record.prepareResult.init.modem;

    const bookedDownloadSpeedMax = nullOrValue(modem.bookedDownloadSpeedMax);
    const bookedDownloadSpeedAvg = nullOrValue(modem.bookedDownloadSpeedAvg);
    const bookedDownloadSpeedMin = nullOrValue(modem.bookedDownloadSpeedMin);

    return (
        <RootDiv data-cy="downloadCard1">
            <LeftPartDiv>
                <SpeedContainer>
                    <Speed currentSpeed={downloadSpeed} bookedSpeedMax={bookedDownloadSpeedMax} />
                    <Percentage
                        currentSpeed={downloadSpeed}
                        bookedSpeedMax={bookedDownloadSpeedMax}
                        bookedSpeedAvg={bookedDownloadSpeedAvg}
                        bookedSpeedMin={bookedDownloadSpeedMin}
                        showAdditionalInfo={showAdditionalInfo}
                    />
                </SpeedContainer>
                <SubtitleContainer>
                    <SubtitleSpan>
                        {connectionSubtitle(record)}
                    </SubtitleSpan>
                </SubtitleContainer>
                {progress(bookedDownloadSpeedMin, bookedDownloadSpeedAvg, bookedDownloadSpeedMax, downloadSpeed, showAdditionalInfo)}
            </LeftPartDiv>
        </RootDiv>
    );
}

export default DownloadCard1;