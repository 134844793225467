import React, { useState } from "react";
import styled from "@emotion/styled";
import NavigationButton from "./NavigationButton";
import t from "../../../helper/t";
import { useAppDispatch } from "../../../hooks";
import FooterShowVersions from "./FooterShowVersions";
import { showConsentDialog } from "../../../features/dialogManager/dialogManagerSlice";
import LanguageSwitch from "../../../components/layout/footer/LanguageSwitch";

const RootElement = styled.footer(({ theme }) => ([
    theme.splus3.background.monochrome600,
    theme.splus3.foreground.white,
    theme.splus3.typography.small,
    {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        flexWrap: "wrap-reverse",
        columnGap: 16,
        minHeight: 60,

        /* for mobile */
        [theme.splus3.breakpoints.mobile]: {
            // height: 60,
        },
    }
]));

const ContentDiv = styled.div(({ theme }) => ([
    {
        display: "flex",
        columnGap: 32,
        rowGap: 8,
        margin: 8,
        flexWrap: "wrap",

        /* for mobile */
        [theme.splus3.breakpoints.mobile]: {
            columnGap: 16,
        },
    }
]));

const FooterText = styled.span([
    props => props.theme.splus3.foreground.white,
    props => props.theme.splus3.typography.small,
    {

    }
]);

const Footer: React.FC<{}> = () => {
    const dispatch = useAppDispatch();

    const [numClicks, setNumClicks] = useState(0);
    const isVersionVisible = (numClicks >= 5) && (numClicks % 2 === 1);

    const item = (text: string, href: string) => {
        return (
            <NavigationButton href={href}>
                <FooterText>
                    {text}
                </FooterText>
            </NavigationButton>
        );
    }

    const localItem = (text: string, onClick: () => void) => {
        return (
            <NavigationButton onClick={onClick}>
                <FooterText>
                    {text}
                </FooterText>
            </NavigationButton>
        );
    }

    return (
        <RootElement onClick={() => { setNumClicks(n => n + 1) }}>
            <div style={{ justifySelf: "start", marginLeft: 12, marginTop: 8, marginBottom: 8 }}>
                <LanguageSwitch />
            </div>
            <ContentDiv>
                {item(`© 2023 ${t("Vodafone GmbH")}`, "https://www.vodafone.de/")}
                {item(t("AGB"), "https://www.vodafone.de/agb.html")}
                {item(t("Impressum"), "https://www.vodafone.de/impressum.html")}
                {item(t("Datenschutz"), "https://www.vodafone.de/unternehmen/soziale-verantwortung/datenschutz-privatsphaere.html")}
                {item(t("Speedtest Datenschutz"), "/privacy_policy_faq")}
                {localItem(t("Speedtest Cookies"), () => dispatch(showConsentDialog()))}
                {item(t("Cookie Policy"), "/cookie_policy")}
            </ContentDiv>
            <div>
                {isVersionVisible && (
                    <FooterShowVersions />
                )}
            </div>
        </RootElement>
    )
}

export default Footer;

