import styled from "@emotion/styled";
import markdownRender from "../../../../helper/markdownRender";
import NavigationButton from "../../elements/NavigationButton";
import { useAppSelector } from "../../../../hooks";
import { nextIsEnabled, prevIsEnabled } from "../../../../features/hints/hintsSlice";
import t from "../../../../helper/t";

import prevSvg from "../../../../../assets/icons/splus3/prev.svg";
import nextSvg from "../../../../../assets/icons/splus3/next.svg";
import Hint3Json from "../../../../features/hints/Hint3Json";

const RootDiv = styled.div(({ theme }) => ([
    theme.splus3.background.white,
    theme.splus3.shadow.mainCard,
    {
        borderRadius: 6,
        paddingTop: 20,
        display: "grid",
        gridTemplateColumns: "1fr auto",
        gridTemplateRows: "repeat(4, auto)",
        columnGap: 16,

        [theme.splus3.breakpoints.mobile]: [
            {
                paddingTop: 12,
            }
        ],
    }
]));

interface SeparatorDivProps {
    gridRow: number;
}

const SeparatorDiv = styled.div<SeparatorDivProps>(({ theme, gridRow }) => ([
    theme.splus3.background.primary,
    {
        display: "block",
        gridRow,
        gridColumnStart: 1,
        gridColumnEnd: 3,
        marginTop: "auto",
        marginLeft: 20,
        marginRight: 20,
        height: 1,

        [theme.splus3.breakpoints.mobile]: {
            marginLeft: 0,
            marginRight: 0,
        },
    }
]));

const TipTitleDiv = styled.div(({ theme }) => ([
    theme.splus3.foreground.monochrome600,
    theme.splus3.typography.font28Lite,
    {
        gridRow: 1,
        gridColumn: 1,
        paddingLeft: 20,
        marginTop: 8,
        marginBottom: 8,
        lineHeight: "32px",

        [theme.splus3.breakpoints.mobile]: [
            theme.splus3.typography.defaultLite,
            {
                gridRow: 2,
                paddingLeft: 12,
                paddingRight: 12,
                lineHeight: "28px",
            }
        ],
    }
]));

const ControlsDiv = styled.div(({ theme }) => ([
    theme.splus3.foreground.monochrome600,
    theme.splus3.typography.small,
    {
        gridRow: 1,
        gridColumn: 2,
        paddingRight: 20,
        marginTop: 8,

        flexWrap: "nowrap",
        whiteSpace: "nowrap",
        lineHeight: "18px",

        strong: [
            theme.splus3.typography.smallBold,
        ],

        [theme.splus3.breakpoints.mobile]: [
            {
                gridColumnStart: 1,
                gridColumnEnd: 3,
                paddingLeft: 12,
                paddingRight: 12,
                paddingBottom: 12,
            }
        ],
    }
]));

const BodyDiv = styled.div(({ theme }) => ([
    {
        marginTop: 32,
        marginBottom: 0,

        gridRow: 3,
        gridColumnStart: 1,
        gridColumnEnd: 3,

        display: "flex",
        flexDirection: "column",
        paddingLeft: 20,
        paddingRight: 20,


        p: [
            {
                marginTop: 0,
                marginBottom: 16,
            }
        ],

        strong: [
            theme.splus3.typography.bold,
        ],

        [theme.splus3.breakpoints.mobile]: [
            {
                marginTop: 24,
                marginBottom: 24,
                paddingLeft: 12,
                paddingRight: 12,

            }
        ],

        img: {
            width: "80px"
        }
    }
]));

const TeaserTitleDiv = styled.div(({ theme }) => ([
    theme.splus3.foreground.monochrome600,
    theme.splus3.typography.defaultBold,
    {
        lineHeight: "32px",
        marginBottom: 16,

        [theme.splus3.breakpoints.mobile]: [
            theme.splus3.typography.smallBold,
            {
                lineHeight: "18px",
                marginBottom: 12,
            }
        ],
    }
]));

interface TeaserDivProps {
    isExpanded: boolean;
}

const TeaserDiv = styled.div<TeaserDivProps>(({ theme, isExpanded }) => ([
    theme.splus3.foreground.monochrome600,
    theme.splus3.typography.default,
    {
        lineHeight: "27px",
        height: isExpanded ? "auto" : 126,
        overflow: "clip",

        [theme.splus3.breakpoints.mobile]: [
            theme.splus3.typography.small,
            {
                lineHeight: "21px",
            }
        ],
    }
]));

const ExpandButtonContainer = styled.div(({ theme }) => ([
    {
        gridRow: 4,
        gridColumnStart: 1,
        gridColumnEnd: 3,
    }
]));

const ExpandButtonContent = styled.div(({ theme }) => ([
    theme.splus3.foreground.red,
    theme.splus3.typography.default,
    {
        padding: 20,
        lineHeight: "28px",
        textAlign: "center",

        [theme.splus3.breakpoints.mobile]: [
            theme.splus3.typography.font16,
            {
                padding: 12,
            }
        ],
    }
]));

const IconTippContent = styled.div(({ theme }) => ([
    {
        maxHeight: 360,
        padding: 20,
        display: "inline-block",
        margin: "auto auto 0",

        img: [
            {
                width: "100%",
                height: "100%",
                maxWidth: 360,
                maxHeight: 360,
                objectFit: "contain",
            }
        ]
    }
]));

interface ContentDivProps {
    isExpanded: boolean | null;
}

const ContentDiv = styled.div<ContentDivProps>(({ theme, isExpanded }) => ([
    {
        overflow: "hidden",
        transition: isExpanded === null ? "" : "max-height 0.2s ease-out",
        maxHeight: isExpanded === true ? 2000 : 0,
    },
]));

interface TipCardProps {
    hint: Hint3Json;
    onNextClick: () => void;
    onPrevClick: () => void;
    onExpandClick: () => void;
}

const TipCard: React.FC<TipCardProps> = ({ hint, onNextClick, onPrevClick, onExpandClick }) => {
    const state = useAppSelector(state => state);
    return (
        <RootDiv data-cy="tip-card">
            <TipTitleDiv>{hint.quickTipTitle}</TipTitleDiv>
            <ControlsDiv>
                <span>
                    {prevIsEnabled(state.hints) && (
                        <NavigationButton onClick={onPrevClick}>
                            <img height={12} src={prevSvg.src} style={{ marginRight: 16, transform: "translate(0, 2px)" }} alt={"Arrow for previous tab"}/>
                        </NavigationButton>
                    )}
                    <strong>{t("Tipp")} {(state.hints.currentIndex ?? 0) + 1}</strong> {t("von")} {state.hints.hintIds.length}
                    {nextIsEnabled(state.hints) && (
                        <NavigationButton onClick={onNextClick}>
                            <img height={12} src={nextSvg.src} style={{ marginLeft: 16, transform: "translate(0, 2px)"}} alt={"Arrow for next tab"}/>
                        </NavigationButton>
                    )}
                </span>
            </ControlsDiv>
            <SeparatorDiv gridRow={1} />
            <BodyDiv>
                <TeaserTitleDiv>{hint.quickTipBoxTitle}</TeaserTitleDiv>
                <TeaserDiv isExpanded={state.hints.isExpanded === true} dangerouslySetInnerHTML={{ __html: markdownRender(hint.quickTipBoxBody) }} />
                <ContentDiv isExpanded={state.hints.isExpanded}>
                    {state.hints.isExpanded && (
                        <>
                            <TeaserDiv isExpanded={state.hints.isExpanded === true} dangerouslySetInnerHTML={{ __html: markdownRender(hint.expandedTipBody) }} />
                            <TeaserTitleDiv>{hint.expandedTipSubline}</TeaserTitleDiv>
                            <TeaserDiv isExpanded={state.hints.isExpanded === true} dangerouslySetInnerHTML={{ __html: markdownRender(hint.expandedTipSublineBody) }} />
                        </>
                    )}
                </ContentDiv>

            </BodyDiv>
            <SeparatorDiv gridRow={3} />
            <ExpandButtonContainer>
                <NavigationButton dataCy="expand-tip-button" onClick={onExpandClick}>
                    <ExpandButtonContent>
                        {state.hints.isExpanded ? t("Weniger anzeigen") : hint.expandedTipTitle}
                    </ExpandButtonContent>
                </NavigationButton>
            </ExpandButtonContainer>
        </RootDiv>
    );
}

export default TipCard;