import { useAppSelector } from "../../hooks";
import { PageKey } from "../../features/pageManager/pageState";
import HomePage from "./home/HomePage";
import ResultPage from "./result/ResultPage";
import InfoPage from "./info/InfoPage";
import styled from "@emotion/styled";
import Navbar from "./elements/Navbar";
import LoggerViewSwitcher from "../app/LoggerViewSwitcher";
import Footer from "./elements/Footer";
import MeasurementPage from "./measurement/MeasurementPage";
import MaintenanceComponent from "./MaintenanceComponent";
import SPlus3DialogSwitcher from "./SPlus3DialogSwitcher";

const Container = styled.div(({ theme }) => ([
  theme.splus3.background.primary,
  {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",

    /* for mobile */
    [theme.splus3.breakpoints.mobile]: [
      {
        minWidth: 320,
      }
    ],
  }
]));

interface IndexComponentProps {
    isSFA: boolean;
}

const IndexComponent: React.FC<IndexComponentProps> = ({isSFA}) => {
  const state = useAppSelector(state => state.pageManager);
  const errorState = useAppSelector(state => state.error);
  const isMaintenance = errorState.lastError !== null && errorState.lastError.scheme === "Sm-1";

  if (isMaintenance) {
    return <MaintenanceComponent />
  }

  const currentContent = () => {
    switch (state.currentPage) {
      case PageKey.Home: return <HomePage />
      case PageKey.Measurement: return <MeasurementPage />
      case PageKey.Result: return <ResultPage />
      case PageKey.Info: return <InfoPage />
    }
  }

  return (
    <Container>
      {!isSFA && <Navbar hideMenu={state.currentPage === PageKey.Measurement} />}
      <main>
        {currentContent()}
        <LoggerViewSwitcher />
        <SPlus3DialogSwitcher />
      </main>
      {!isSFA && (state.currentPage !== PageKey.Measurement) && <Footer />}
    </Container >
  );
}

export default IndexComponent;
