import styled from "@emotion/styled";
import LottiePlayer from "../../../../components/elements/spinner/LottiePlayer";
import { useAppSelector } from "../../../../hooks";
import { isSpeedtestRunning } from "../../../../features/measurement/utils";
import ProgressAnimationWrapper from "../../../../features/measurement/ProgressAnimationWrapper";

import middleAnimationData from "../../../../../assets/spinner/VF_Living_Speechmark_drawn_on_Lottie_Red_1200.json";
import speechmarkSvg from "../../../../../assets/images/splus3/speechmark_148.svg";

const SpeechmarkDiv = styled.div(({ theme }) => ([
    {
        position: "relative",
        width: 148,

        /* for mobile */
        [theme.splus3.breakpoints.mobile]: [
            {
                width: 100,
            }
        ],
    }
]));

const ProgressTextDiv = styled.div(({ theme }) => ([
    theme.splus3.typography.smallBold,
    theme.splus3.foreground.monochrome600,
    {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",

        transform: "translate(0px, 8px)",

        /* for mobile */
        [theme.splus3.breakpoints.mobile]: [
            {
                transform: "translate(0px, 4px)",
            }
        ],
    }
]));

const ProgressAnimation: React.FC = () => {
    const state = useAppSelector(s => s.measurement);

    const childFunc = (percentage: number) => {
        return (
            <SpeechmarkDiv>
                {isSpeedtestRunning(state) ? (
                    <LottiePlayer animationData={middleAnimationData} />
                ) : (
                    <img width="100%" src={speechmarkSvg.src} alt={"Vodafone Logo"}/>
                )}
                <ProgressTextDiv>{`${percentage}%`}</ProgressTextDiv>
            </SpeechmarkDiv>
        );
    }

    return <ProgressAnimationWrapper childFunc={childFunc} />

}

export default ProgressAnimation;