import { ModemFinalKind, ModemNotRunReason } from "@visonum/network-quality-sdk";
import t from "../../../../../helper/t";
import { NQRecord } from "../../../../../features/history/types";
import { getSpeedKind } from "../../common/resultUtils";
import ProgressBar from "../../common/ProgressBar";
import { getDeviceText } from "src/helper/getDeviceText";
import { connectionToStr } from "../../utils";

export const showRouterSpeed = (record: NQRecord): boolean => {
    const modemFinal = record.modemFinal.modemFinal;
    const isModemTestAvailable = record.prepareResult.init.cmts?.isModemTestAvailable == true;
    switch (modemFinal.kind) {
        case ModemFinalKind.ModemFinalAvailable: return true;
        case ModemFinalKind.ModemFinalFailed: return isModemTestAvailable;
        case ModemFinalKind.ModemFinalDidNotRun:
            switch (modemFinal.reason) {
                case ModemNotRunReason.NotNeeded: return false;
                case ModemNotRunReason.NotAvailable: return isModemTestAvailable;
                case ModemNotRunReason.NotACustomer: return false;
            }
    }
}

export const progress = (speedMin: number | null, speedAvg: number | null, speedMax: number | null, currentSpeed: number, showAdditionalInfo: boolean) => {
    if (speedMax === null ||
        speedAvg === null ||
        speedMin === null) {
        return null;
    }

    const speedKind = getSpeedKind(currentSpeed, speedAvg, speedMin);

    return (
        <ProgressBar speedKind={speedKind} min={speedMin} avg={speedAvg} max={speedMax} current={currentSpeed} showCompareText={showAdditionalInfo} />
    );
}

export const connectionSubtitle = (record: NQRecord) => {
    const deviceName = getDeviceText(record.prepareResult, t("Messung an Deinem Gerät"));
    const connectionStr = connectionToStr(record.modemFinal.connectionType);
    return <>{deviceName} {connectionStr === null ? "" : `(${connectionStr})`}</>
}